export enum SelectRoles {
  Owner = 'Owner',
  Affiliate = 'Affiliate',
  Lead = 'Lead',
  Writer = 'Writer',
  Reader = 'Reader'
}

export type Role = {
  role: string;
  displayText: string;
  index: number;
};

export enum USER_ROLES {
  Admin = 'Admin',
  Owner = 'Owner',
  Affiliate = 'Affiliate',
  Lead = 'Lead',
  Writer = 'Writer',
  Reader = 'Reader',
  NONE = 'None'
}

export enum USER_STATUS {
  Active = 'Active',
  Pending = 'Pending',
  Verified = 'Verified'
}

export const UserRoleLevels: { [key in USER_ROLES]: number } = {
  [USER_ROLES.Owner]: 0,
  [USER_ROLES.Admin]: 10,
  [USER_ROLES.Affiliate]: 100,
  [USER_ROLES.Lead]: 300,
  [USER_ROLES.Writer]: 400,
  [USER_ROLES.Reader]: 500,
  [USER_ROLES.NONE]: 1000
};
export const userRoles: Role[] = [
  { role: 'Owner', displayText: 'Owner', index: 0 },
  { role: 'Affiliate', displayText: 'Affiliate', index: 1 },
  { role: 'Lead', displayText: 'Lead', index: 2 },
  { role: 'Writer', displayText: 'Writer', index: 3 },
  { role: 'Reader', displayText: 'Reader', index: 4 }
];
