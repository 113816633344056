import { NavigationGuardNext, RouteRecordRaw } from 'vue-router';
import { useCampaignStore } from '@/store/campaign';
import { USER_ROLES } from '@/declare/enums/user.enum';

const Leads = (): Promise<typeof import('@/pages/Leads.vue')> => import('@/pages/Leads.vue');
const LeadCards = (): Promise<typeof import('@/pages/LeadCards.vue')> =>
  import('@/pages/LeadCards.vue');
const Calls = (): Promise<typeof import('@/pages/Calls.vue')> => import('@/pages/Calls.vue');
export const campaignRoutes: RouteRecordRaw[] = [
  {
    path: 'leads',
    component: Leads,
    meta: { requiresAuth: true, businessRequired: USER_ROLES.NONE },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-declare
    beforeEnter: (to, from, next: NavigationGuardNext): void => {
      const campaignStore = useCampaignStore();
      if (campaignStore.hasCreatedCampaign && !campaignStore.virtualNumberCreated) {
        campaignStore.setShowVirtualNumberModal(true);
      }
      next();
    }
  },
  {
    path: 'cards',
    component: LeadCards,
    meta: { requiresAuth: true, businessRequired: USER_ROLES.NONE }
  },
  {
    path: 'leads/:id',
    component: Leads,
    meta: { requiresAuth: true, businessRequired: USER_ROLES.NONE }
  },
  // {
  //   path: 'create-lead',
  //   component: CreateLead,
  //   meta: {requiresAuth: true}
  // },
  {
    name: 'Calls',
    path: 'calls',
    component: Calls,
    meta: { requiresAuth: true, businessRequired: USER_ROLES.Owner }
  }
];

export default campaignRoutes;
