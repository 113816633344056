<template>
  <div class="popup-overlay" v-if="active"></div>
  <div class="popup" v-if="active">
    <div class="popup__success">
      <!--      <div>-->
      <!--        <img src="../../assets/img/success.svg" alt="" style="width:135px;">-->
      <!--      </div>-->

      <div class="popup__title">
        {{ translate('Auth.hi_message', { name: currentUser?.email }) }},
      </div>
      <div class="popup__text">{{ trans('Please provide your name to continue') }}</div>
      <div class="auth__content">
        <form @submit.prevent="onSubmit" ref="form">
          <div class="profile-card-info__item">
            <div class="profile-card-info__label">{{ trans('Full name') }}</div>
            <div class="input-wrapper">
              <input
                type="text"
                name="name"
                v-model="name"
                :placeholder="trans('Enter your full name')"
              />
              <ErrorMsg name="name" />
            </div>
          </div>
          <div class="auth__btn">
            <button class="btn" :disabled="!isValidForm || disabledSubmit">
              {{ trans('Save') }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { trans, translate } from '@/common/language/translations';

import { computed, defineComponent, ref, watch } from 'vue';
import ErrorMsg from '@/components/UI/ErrorMsg.vue';
import { toTypedSchema } from '@vee-validate/zod';
import * as zod from 'zod';
import { useField, useForm } from 'vee-validate';
import axios from '@/common/lib/axios';
import { User_URL } from '@/common/lib/url';
import useAuthStore from '@/store/auth';
import { IUser } from '@/declare/types/user';

export default defineComponent({
  name: 'MissingName',
  components: { ErrorMsg },
  setup() {
    const authStore = useAuthStore();
    const currentUser = authStore.currentUser as IUser;
    const active = computed(() => !currentUser.name || currentUser.name.includes('@'));
    const isValidForm = ref(false);
    const disabledSubmit = ref(false);

    const validationSchema = toTypedSchema(
      zod.object({
        name: zod.string().min(2)
      })
    );

    const { handleSubmit, errors } = useForm({
      validationSchema
    });
    const { value: name } = useField('name');

    watch([name, errors], ([nameValue, errorsValue]) => {
      isValidForm.value = !!nameValue && Object.keys(errorsValue).length === 0;
    });

    const onSubmit = handleSubmit(async (values: { name: string }) => {
      try {
        const response = await axios.post(User_URL + 'update', {
          name: values.name
        });
        if (response.data.success) {
          authStore.setCurrentUser(response.data.updatedUser);
        }
      } catch (e) {
        console.log(e);
      }
    });

    return {
      name,
      currentUser,
      isValidForm,
      disabledSubmit,
      active,
      onSubmit,
      translate,
      trans
    };
  }
});
</script>

<!--<style scoped lang="scss">-->
<!--@import '@/assets/styles/utils/mixins';-->
<!--@import '@/assets/styles/utils/vars';-->

<!--.popup-overlay {-->
<!--  width: 100%;-->
<!--  height: 100%;-->
<!--  background: rgba(0, 0, 0, 0.5);-->
<!--  position: fixed;-->
<!--  top: 0;-->
<!--  left: 0;-->
<!--  z-index: 5;-->
<!--}-->

<!--.popup {-->
<!--  width: 560px;-->
<!--  min-height: 440px;-->
<!--  max-width: calc(100vw - 30px);-->
<!--  border-radius: 45px;-->
<!--  background: #fff;-->
<!--  padding: 62px 15px;-->
<!--  text-align: center;-->
<!--  position: fixed;-->
<!--  top: 50%;-->
<!--  inset-inline-start: 50%;-->
<!--  transform: translate(-50%, -50%);-->
<!--  z-index: 5;-->

<!--  &__title {-->
<!--    @include font(none, 500, 2.6rem, 1.4, #000);-->
<!--  }-->

<!--  &__text {-->
<!--    max-width: 250px;-->
<!--    @include font(none, 200, 1.2rem, 1.6, #000);-->
<!--    margin: 14px auto 0;-->
<!--  }-->

<!--  .code-verif {-->
<!--    margin-top: 43px;-->
<!--  }-->

<!--  .timer {-->
<!--    margin-top: 42px;-->
<!--  }-->

<!--  &__bottom {-->
<!--    margin-top: 27px;-->
<!--    @include font(none, none, 1.3rem, 1.6, #000);-->
<!--  }-->

<!--  &__note {-->
<!--    @include font(none, 200, 1.2rem, 1.6, #969696);-->
<!--  }-->

<!--  &__link:hover {-->
<!--    cursor: pointer;-->
<!--    text-decoration: underline;-->
<!--  }-->

<!--  &__btn {-->
<!--    margin-top: 57px;-->

<!--    .btn {-->
<!--      width: 220px;-->
<!--      max-width: 100%;-->
<!--    }-->
<!--  }-->

<!--  &__icon {-->
<!--    margin-bottom: 44px;-->
<!--  }-->

<!--  &__success {-->
<!--    display: flex;-->
<!--    flex-direction: column;-->
<!--    align-items: center;-->
<!--    justify-content: center;-->
<!--    height: 316px;-->
<!--  }-->
<!--}-->
<!--</style>-->
