<template>
  <div class="choose-acc" :class="{ open: openPopup }" v-click-outside="handleClose">
    <div class="choose-acc__top" @click="openPopup = !openPopup">
      <div
        class="choose-acc__img"
        :style="getBackgroundImageOrColor(currentBusiness?.image!)"
      ></div>

      <div class="choose-acc__text">{{ currentBusiness?.name ?? 'Select Business' }}</div>
      <div class="choose-acc__arr">
        <svg class="icon ic-arrow-down" width="11" height="6">
          <use xlink:href="@/assets/sprites/sprite.svg#ic-arrow-down"></use>
        </svg>
      </div>
    </div>

    <div class="choose-acc-popup">
      <div class="choose-acc-popup__search">
        <input type="text" :placeholder="trans('Search')" v-model="searchQuery" />
        <svg class="icon ic-search" width="15" height="15">
          <use xlink:href="@/assets/sprites/sprite.svg#ic-search"></use>
        </svg>
      </div>

      <div
        class="choose-acc-popup__item"
        v-for="item in filteredBusiness"
        :key="item._id"
        @click="changeOption(item._id)"
        :class="{ active: item._id === currentBusiness?._id }"
      >
        <div class="choose-acc-popup__content">
          <div
            class="choose-acc-popup__img"
            v-if="item?.image"
            :style="getBackgroundImageOrColor(item.image)"
          ></div>

          <div class="choose-acc-popup__info">
            <div class="choose-acc-popup__title">{{ item.name }}</div>
            <div class="choose-acc-popup__mail">{{ item.email }}</div>
          </div>
        </div>

        <div class="choose-acc-popup__check" v-if="item._id == currentBusiness?._id">
          <svg class="icon ic-tick" width="8" height="6">
            <use xlink:href="@/assets/sprites/sprite.svg#ic-tick"></use>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { trans } from '@/common/language/translations';

import { computed, ref } from 'vue';
import useAuthStore from '@/store/auth';
import { getBackgroundImageOrColor } from '@/common/utils/image.util';
import { IBusiness } from '@/declare/types/business';

const authStore = useAuthStore();
const openPopup = ref<boolean>(false);
const searchQuery = ref<string>('');

const currentBusiness = computed(() => authStore.currentBusiness);
const businesses = computed(() => authStore.businesses);

const filteredBusiness = computed(() => {
  const size = businesses.value ? businesses.value.length : 4;

  if (searchQuery.value.trim() === '') {
    return businesses.value && businesses.value.slice(0, size);
  }

  return (
    businesses.value &&
    businesses.value.filter(campaign =>
      campaign.name.toLowerCase().includes(searchQuery.value.toLowerCase())
    )
  );
});

async function changeOption(id: string): Promise<void> {
  try {
    const selectedBusiness =
      businesses.value && businesses.value.find((business: IBusiness) => business._id === id);
    if (selectedBusiness) {
      await authStore.setCurrentBusiness(selectedBusiness);
    }
  } catch (error) {
    console.error('Error fetching business:', error);
  } finally {
    handleClose();
  }
}

function handleClose(): void {
  openPopup.value = false;
}
</script>

<style lang="scss"></style>
