export type HistoryTab = {
  displayText: string;
  value: LeadHistorySection;
};

export enum LeadHistoryType {
  CALL = 'CALL',
  ACTION = 'ACTION',
  FILE = 'FILE',
  COMMENT = 'COMMENT'
}

export enum LeadActions {
  SendEmail = 'Send Email',
  SendSMS = 'Send SMS',
  CallBack = 'Call Back',
  None = 'None'
}

export enum LEAD_RATING {
  Rating1 = 'Rating1',
  Rating2 = 'Rating2',
  Rating3 = 'Rating3'
}

export enum LEAD_STATUS {
  New = 'New',
  Paused = 'Paused',
  Decline = 'Decline',
  Waiting = 'Waiting'
}

export enum LeadHistorySection {
  All = 'All',
  Files = 'Files',
  Calls = 'Calls',
  Comments = 'Comments'
}
