import { Router } from 'vue-router';
import useLoadingStore from '@/store/loading';
import useAuthStore from '@/store/auth';
import { computed } from 'vue';
import useLeadStore from '@/store/lead';
import { USER_ROLES, UserRoleLevels } from '@/declare/enums/user.enum';

export default function AuthMiddleware(router: Router): void {
  const authStore = useAuthStore();
  const loadingStore = useLoadingStore();

  router.beforeEach(async (to, from, next) => {
    loadingStore.startRouteLoading();

    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const businessRequired: USER_ROLES | null = (to.matched as any[]).reduce(
      (role, record) => {
        return record.meta.businessRequired &&
          (role === null ||
            UserRoleLevels[role as USER_ROLES] <
              UserRoleLevels[record.meta.businessRequired as USER_ROLES])
          ? record.meta.businessRequired
          : role;
      },
      null as USER_ROLES | null
    );

    if (requiresAuth || businessRequired) {
      const isLoggedIn = computed(() => authStore.isLoggedIn);

      if (!isLoggedIn.value && localStorage.getItem('token')) {
        await authStore.fetchCurrentUser().catch(() => {
          if (to.path !== '/auth/register' && to.path !== '/auth/login') {
            next('/auth/login');
            return;
          }
        });
      }

      if (!isLoggedIn.value && to.path !== '/auth/register' && to.path !== '/auth/login') {
        next('/auth/login');
        return;
      }
    }

    if (businessRequired) {
      const business = computed(() => authStore.currentBusiness);
      if (
        !business.value ||
        UserRoleLevels[business.value.role] > UserRoleLevels[businessRequired]
      ) {
        next('/main');
        return;
      }
    }
    if (from.path === '/leads') {
      const leadStore = useLeadStore();
      leadStore.selectAll = false;
      leadStore.selectedLeads = [];
    }
    next();
  });

  router.afterEach(() => {
    loadingStore.endRouteLoading();
  });
}
