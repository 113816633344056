import { defineStore } from 'pinia';
import { Column, Filter, IPagination } from '@/declare/interfaces';
import axios from '@/common/lib/axios';
import { trans } from '@/common/language/translations';

import { ApiResponse } from '@/declare/types/api';
import { Lead_URL } from '@/common/lib/url';
import { get } from '@/common/lib/api';
import { showToast } from '@/common/lib/toast';
import { LeadTableData, LeadTableHeaderNames } from '@/declare/types/lead';
import { LeadHistorySection } from '@/declare/enums/lead.enum';

type GetLeadsResponse = {
  success: boolean;
  leads: LeadTableData[];
  pagination: IPagination;
};

export const useLeadStore = defineStore({
  id: 'lead-store',
  state: () => ({
    leadTableColumns: [
      { key: 'name', text: 'Lead name', status: true },
      { key: 'phone', text: 'Phone', status: true },
      { key: 'email', text: 'Email', status: true },
      { key: 'status', text: 'Status', status: true },
      { key: 'source', text: 'Source', status: true },
      { key: 'date', text: 'Date', status: true },
      { key: 'meeting', text: 'Meeting Status', status: true },
      { key: 'utm', text: 'UTM', status: true }
      // { key: 'campaignName', title: 'Campaign', status: true }
      // { key: 'star', text: trans('Rating rate'), status: true }
    ] as Column[],
    leadTableHeaders: [
      { key: 'name', title: 'Name', status: true },
      { key: 'phone', title: 'Phone', status: true },
      { key: 'email', title: 'Email', status: true },
      { key: 'status', title: 'Status', status: true },
      { key: 'source', title: 'Source', status: true },
      { key: 'date', title: 'Date & Time', status: true },
      { key: 'meeting', title: 'Meeting', status: true },
      { key: 'utm', title: 'UTM', status: true },
      { key: 'call_status', title: 'Call Status', status: true },
      { key: 'start_call', title: 'Start Call', status: true },
      { key: 'duration', title: 'Duration', status: true },
      { key: 'recordLink', title: 'Call Link', status: true }
      // { key: 'star', isSVG: true, svgName: 'ic-star-fill', status: true }
    ] as LeadTableHeaderNames[],
    filteredLeads: {} as Record<string, LeadTableData[]>,
    selectedLeads: [] as string[],
    currentLead: null as LeadTableData | null,
    selectAll: false as boolean,
    openPopupId: null as string | null,
    historyTab: LeadHistorySection.All,
    openLeadTransferPopup: false,
    openLeadActionsPanel: false,
    openCallBackModal: false,
    openMeetingModal: false,
    allLeads: false,
    leadToCallBack: null as LeadTableData | null,
    redirectedToLeads: false
  }),
  getters: {
    getLeadTableColumns: state => {
      return state.leadTableColumns;
    },
    getLeadTableHeaders: state => {
      return state.leadTableHeaders;
    },
    getFilteredLeads: state => {
      return state.filteredLeads;
    },
    getSelectedLeads: state => {
      return state.selectedLeads;
    },
    getOpenPopupId: state => {
      // New getter
      return state.openPopupId;
    },
    getHistoryTab: state => {
      return state.historyTab;
    },
    getOpenLeadTransferPopup: state => {
      return state.openLeadTransferPopup;
    },
    getCurrentLead: state => {
      return state.currentLead;
    }
  },
  actions: {
    async getLeadsOfCampaign(
      campaignIds: string[],
      page = 1,
      limit = 10,
      filters: Filter[] = [],
      search: string = '',
      all: boolean = false
    ): Promise<GetLeadsResponse> {
      const params = new URLSearchParams({
        campaignId: campaignIds.join(','),
        page: String(page),
        limit: String(limit),
        filters: encodeURIComponent(JSON.stringify(filters)),
        search,
        all: String(all)
      });

      try {
        let response;

        if (search && search.trim().length > 0) {
          response = await axios.get(`${Lead_URL}?${params.toString()}`);
        } else {
          const [status, privateResponse] = await get<{
            success: boolean;
            response: { leads: any[]; pagination: any };
          }>(`${Lead_URL}?${params.toString()}`);

          if (status !== 200 || !privateResponse.success) {
            return { success: false, leads: [], pagination: {} };
          }

          response = { data: privateResponse };
        }

        if (response.data.success) {
          if (response.data.response.leads && response.data.response.pagination) {
            return {
              success: true,
              leads: response.data.response.leads,
              pagination: response.data.response.pagination
            };
          } else {
            return { success: true, leads: [], pagination: {} };
          }
        }

        return { success: false, leads: [], pagination: {} };
      } catch (error) {
        console.error('Error fetching leads:', error);
        return { success: false, leads: [], pagination: {} };
      }
    },
    async deleteLeads(leadIds: string[], campaignId: string): Promise<ApiResponse> {
      let response: ApiResponse;
      try {
        response = await axios.post(`${Lead_URL}delete`, {
          leadIds,
          campaignId
        });
        if (response.data.success) {
          showToast(trans('Operation succeeded'), 'success');
          return { data: { success: true, message: response.data.message } };
        } else {
          showToast(trans('Operation failed'), 'error');
          return { data: { success: false, message: response.data.message } };
        }
      } catch (e) {
        console.log(e);
        return { data: { success: false, message: 'Error deleting lead' } };
      }
    },
    reset() {
      this.$reset();
    },
    setLeadTableColumns(columns: Column[]) {
      this.$patch({ leadTableColumns: columns });
    },
    setSelectedLeads(leads: string[]) {
      this.$patch({ selectedLeads: leads });
    },
    setOpenPopupId(id: string | null) {
      this.$patch({ openPopupId: id });
    },
    setHistoryTab(tab: LeadHistorySection) {
      this.$patch({ historyTab: tab });
    },
    setOpenLeadTransferPopup(open: boolean) {
      this.$patch({ openLeadTransferPopup: open });
    },
    setOpenLeadActionsPanel(open: boolean) {
      this.$patch({ openLeadActionsPanel: open });
    },
    setOpenCallBackModal(open: boolean) {
      this.$patch({ openCallBackModal: open });
    },
    setLeadToCallBack(lead: LeadTableData | null) {
      this.$patch({ leadToCallBack: lead });
    },
    setCurrentLead(lead: LeadTableData | null) {
      this.$patch({ currentLead: lead });
    },
    setAllLeads(all: boolean) {
      this.$patch({ allLeads: all });

      const campaignHeader = {
        key: 'campaignName',
        title: trans('Campaign'),
        status: true
      };
      const campaignColumn = {
        key: 'campaignName',
        text: trans('Campaign'),
        status: true
      };

      if (all) {
        if (!this.leadTableHeaders.some(header => header.key === 'campaignName')) {
          this.leadTableHeaders.push(campaignHeader);
        }
        if (!this.leadTableColumns.some(column => column.key === 'campaignName')) {
          this.leadTableColumns.push(campaignColumn);
        }
      } else {
        this.leadTableHeaders = this.leadTableHeaders.filter(
          header => header.key !== 'campaignName'
        );
        this.leadTableColumns = this.leadTableColumns.filter(
          column => column.key !== 'campaignName'
        );
      }
    }
  },
  persist: false
});

export default useLeadStore;
