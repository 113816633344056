import { createApp } from 'vue';
import App from '@/App.vue';
import router from '@/router/router';
import '@/assets/styles/utils/style.scss';
import VCalendar from 'v-calendar';
import VueDatePicker from '@vuepic/vue-datepicker';
import 'v-calendar/style.css';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '@vuepic/vue-datepicker/dist/main.css';
import { createPinia } from 'pinia';
import { setupGlobalErrorHandler } from '@/plugins/globalErrorHandler';
import svg4everybody from 'svg4everybody';
import MobileDetectionPlugin from '@/plugins/mobileDetection';
import { MOBILE_VIEW_THRESHOLD } from '@/common/lib/constant';
import visibility from '@/common/directives/visibility';
import Vue3Toastify from 'vue3-toastify';
import AuthMiddleware from '@/router/auth.middleware';
import useLoadingStore from '@/store/loading';
import setDirectives from '@/common/directives';

const app = createApp(App);
export const pinia = createPinia();

svg4everybody();
setDirectives(app);
setupGlobalErrorHandler(app);

app.directive('visibility', visibility);

app.component('VueDatePicker', VueDatePicker);

app
  .use(pinia)
  .use(Vue3Toastify)
  .use(VCalendar, {})
  .use(MobileDetectionPlugin, { thresholdWidth: MOBILE_VIEW_THRESHOLD });

// Reset loading state
const loadingStore = useLoadingStore();
loadingStore.resetLoadingState();

AuthMiddleware(router);

app.use(router);
app.mount('#app');
