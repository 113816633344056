<template>
  <div class="header-auth" :class="{ open: openPopup }" v-click-outside="handleClose">
    <div class="header-auth__top" @click="openPopup = !openPopup">
      <div class="header-auth__img" :style="userBackgroundStyle"></div>

      <div class="header-auth__info">
        <div class="header-auth__name">{{ currentUser?.name }}</div>
        <div class="header-auth__mail">{{ currentUser?.email }}</div>
      </div>
      <div class="header-auth__arr" :class="{ open: openPopup }">
        <svg class="icon ic-arrow-down" width="13" height="8">
          <use xlink:href="@/assets/sprites/sprite.svg#ic-arrow-down"></use>
        </svg>
      </div>
    </div>
    <!--    TODO: Add item.label to translation list-->
    <div class="header-auth__popup" @click.stop>
      <router-link v-for="item in menuItems" :key="item.label" :to="item.to" :class="item.class">
        {{ trans(item.label) }}
      </router-link>
      <div class="header-auth__exit" @click.prevent="authStore.logout()">
        <svg class="icon ic-logout" width="16" height="16">
          <use xlink:href="@/assets/sprites/sprite.svg#ic-logout"></use>
        </svg>
        {{ trans('Logout') }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { TranslationKeys } from '@/common/language/translator';
import { trans } from '@/common/language/translations';
import { computed, ref, watch } from 'vue';
import { useAuthStore } from '@/store/auth';
import { getBackgroundImageOrColor } from '@/common/utils/image.util';

interface MenuItem {
  label: TranslationKeys;
  to: string;
  class: string;
  condition?: boolean;
}

const authStore = useAuthStore();
const openPopup = ref(false);

const currentUser = computed(() => authStore.getCurrentUser);
const menuItems = computed(() => {
  const items: MenuItem[] = [
    // TODO: Hidden - Add when the feature is ready
    // {
    //   label: 'My shifts',
    //   to: '/user/settings/profile',
    //   class: 'header-auth__item',
    // },
    {
      label: 'My profile',
      to: '/user/settings/profile',
      class: 'header-auth__item'
    }
  ];
  return items;
});
const userBackgroundStyle = computed(() => {
  const imageName = currentUser.value?.image || '';
  return getBackgroundImageOrColor(imageName);
});

function handleClose(): void {
  openPopup.value = false;
}

watch(
  () => authStore.isLoggedIn,
  newVal => {
    authStore.setLoggedInState(newVal);
  },
  { immediate: true }
);
</script>

<style lang="scss">
@import '@/assets/styles/header-auth.scss';
</style>
