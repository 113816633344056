<template>
  <div class="header">
    <div v-if="!isMobile" class="btn-element-tooltip">
      <SelectBusiness />
    </div>

    <div class="header__content">
      <!--      TODO: Hidden - reveal the commented out code when is ready-->
      <!--      <my-button v-if="!isMobile" class="btn_green">-->
      <!--        <svg class="icon ic-shift" width="17" height="17" fill-rule="evenodd">-->
      <!--          <use xlink:href="@/assets/sprites/sprite.svg#ic-shift"></use>-->
      <!--        </svg>-->
      <!--        {{ trans('Start shift') }}-->
      <!--      </my-button>-->

      <header-auth v-if="!isMobile" />

      <div class="header-action">
        <!--      TODO: Hidden - reveal the commented out code when is ready-->

        <!--        <div v-if="!isMobile" class="header-action__item">-->
        <!--          <svg class="icon ic-msg" width="24" height="24">-->
        <!--            <use xlink:href="@/assets/sprites/sprite.svg#ic-msg"></use>-->
        <!--          </svg>-->
        <!--        </div>-->

        <div v-if="isMobile" class="header-action__item-menu" @click.stop="toggleMobileSideBar">
          <svg class="icon ic-menu-lines" width="18" height="21">
            <use xlink:href="@/assets/sprites/sprite.svg#ic-menu-lines"></use>
          </svg>
        </div>

        <div class="header-action__item" @click.stop="toggleNotifications">
          <svg class="icon ic-notice" width="24" height="24">
            <use xlink:href="@/assets/sprites/sprite.svg#ic-notice"></use>
          </svg>
          <div v-if="notificationCount > 0" class="notice-item__circle"></div>
        </div>
      </div>
    </div>
  </div>

  <Transition name="fade">
    <div v-show="isAnyModalOpen" class="site-shadow" @click="handleClose"></div>
  </Transition>
  <MobileSidebar
    v-if="isMobile && openMobileSideBar"
    :class="{ open: isMobile && openMobileSideBar }"
    @close="openMobileSideBar = false"
  />
  <Notifications v-if="openNotice" :open-notice="openNotice" @close="toggleNotifications" />
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import SelectBusiness from '@/components/Header/SelectBusiness.vue';
import HeaderAuth from '@/components/Header/HeaderAuth.vue';
import MobileSidebar from '@/mobile_components/Mob_SideBar.vue';
import Notifications from '@/components/Notifications/Notifications.vue';
import { useNotificationStore } from '@/store/notification';
import { useUIStore } from '@/store/ui';

const uiStore = useUIStore();
const openNotice = ref(false);
const openMobileSideBar = ref(false);

const notificationCount = computed(() => useNotificationStore().unreadCount);
const isMobile = computed(() => uiStore.isMobile);
const isAnyModalOpen = computed(() => {
  return openNotice.value && !isMobile.value && openMobileSideBar.value;
});

const toggleNotifications = () => {
  openNotice.value = !openNotice.value;
  if (openNotice.value) {
    openMobileSideBar.value = false;
  }
};

const toggleMobileSideBar = () => {
  openMobileSideBar.value = !openMobileSideBar.value;
  if (openMobileSideBar.value) {
    openNotice.value = false;
  }
};

function handleClose() {
  if (openNotice.value) {
    toggleNotifications();
  }
  if (openMobileSideBar.value) {
    toggleMobileSideBar();
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/header-page.scss';
</style>
