import { defineStore } from 'pinia';
import { MOBILE_VIEW_THRESHOLD } from '@/common/lib/constant';

export type LeadView = 'table' | 'cards';

interface TableSettings {
  [key: string]: {
    recordsPerPage: number;
  };
}

const MIN_RECORD: number = 10;

interface UIState {
  leadView: LeadView;
  tableSettings: TableSettings;
  mainBarOpen: boolean;
  mobileMainBarOpen: boolean;
  modalState: Record<string, boolean>;
  isMobile: boolean;
  openMediaPlayer: boolean;
  allLeads: boolean;
  allCalls: boolean;
}

export const useUIStore = defineStore('ui', {
  state: (): UIState => ({
    leadView: 'table',
    tableSettings: {
      campaign: { recordsPerPage: MIN_RECORD },
      lead: { recordsPerPage: MIN_RECORD },
      user: { recordsPerPage: MIN_RECORD },
      call: { recordsPerPage: MIN_RECORD },
      invited: { recordsPerPage: MIN_RECORD }
    },
    mainBarOpen: true,
    mobileMainBarOpen: true,
    modalState: {},
    isMobile: window.innerWidth <= MOBILE_VIEW_THRESHOLD,
    openMediaPlayer: false,
    allLeads: false,
    allCalls: false
  }),
  getters: {
    getLeadView: (state: UIState): LeadView => state.leadView,
    getRecordsPerPage:
      state =>
      (tableType: string): number => {
        return state.tableSettings[tableType]?.recordsPerPage;
      },
    isMobileOn: (state: UIState): boolean => state.isMobile,
    isMainBarOpen: state => state.mainBarOpen,
    isMobileSideBarOpen: state => state.mobileMainBarOpen,
    isModalOpen:
      state =>
      (key: string): boolean => {
        return state.modalState[key] || false;
      },
    isMediaPlayerOpen: state => state.openMediaPlayer
  },
  actions: {
    reset() {
      this.$reset();
    },
    setAllLeads(value: boolean) {
      this.allLeads = value;
    },
    setAllCalls(value: boolean) {
      this.allCalls = value;
    },
    setLeadView(view: LeadView) {
      this.leadView = view;
    },
    setRecordsPerPage(tableType: string, recordsPerPage: number) {
      if (this.tableSettings[tableType]) {
        this.tableSettings[tableType].recordsPerPage = recordsPerPage;
      }
    },
    toggleSideBar() {
      this.mainBarOpen = !this.mainBarOpen;
    },
    toggleMobileSideBar(value?: boolean) {
      this.mobileMainBarOpen = value ?? !this.mobileMainBarOpen;
    },
    toggleMediaPlayer(value: boolean) {
      this.$patch({ openMediaPlayer: value });
    },
    updateViewport(value: boolean) {
      this.$patch({ isMobile: value });
    }
  },
  persist: true
});
